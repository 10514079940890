<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Добавление акции</div>
                <div class="page__desc">Введите данные новой акции</div>
            </div>
        </div>

        <div class="page__content">
            <form class="form-group" method="POST" action="" @submit.prevent="create()">

                <label for="title">Название</label>
                <div class="control-group">
                    <input
                            v-model="title"
                            id="title"
                            type="text"
                            name="title"
                            class="form-control"
                            :readonly="is_weekly === 1"
                            required>
                </div>
                <br>

                <label for="discount">Скидка (%)</label>
                <div class="control-group">
                    <input
                            v-model="discount"
                            id="discount"
                            type="text"
                            name="discount"
                            class="form-control"
                            placeholder="15"
                            required>
                </div>
                <br>

                <label for="time_from">Период</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="time_from"
                                    id="time_from"
                                    type="datetime-local"
                                    name="time_from"
                                    class="form-control"
                                    :readonly="is_weekly === 1"
                                    required>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="time_to"
                                    id="time_to"
                                    type="datetime-local"
                                    name="time_to"
                                    class="form-control"
                                    :readonly="is_weekly === 1"
                                    required>
                        </div>
                    </div>
                </div>
                <br>

                <label for="photo">Фото</label><br>
                <div class="control-group">
                    <input ref="photo" id="photo" type="file" name="photo" class="form-control">
                </div>
                <br>

                <label for="product_ids">Продукты</label>
                <div class="control-group">
                    <select
                            v-model="product_ids"
                            id="product_ids"
                            class="form-control"
                            style="height: 200px"
                            multiple required>

                        <template
                                v-for="cc in categories"
                                :label="cc['title']">

                            <optgroup
                                    v-bind:key="cc['id']"
                                    :label="cc['title']"></optgroup>

                            <optgroup
                                    v-for="c in cc['categories']"
                                    v-bind:key="c['id']"
                                    :label="'↳' + c['title']">

                                <option
                                        v-for="p in c['products']"
                                        v-bind:key="p['id']"
                                        :value="p['id']">{{ p['title'] }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <label v-if="is_weekly === 0" for="is_visible"><input v-model="is_visible" type="checkbox" id="is_visible" name="is_visible"> Показывать на главной?</label>
                <br><br>

                <label v-if="is_weekly === 0" for="is_carousel"><input v-model="is_carousel" type="checkbox" id="is_carousel" name="is_carousel"> Отдельная карусель с товарами?</label>
                <br><br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CatalogPromotionsCreate',
        data() {
            return {
                title: null,
                discount: null,
                is_visible: 0,
                is_carousel: 0,
                product_ids: [],
                time_from: null,
                time_to: null,
                is_weekly: 0
            }
        },
        computed: {
            categories() {
                return this.$store.state.promotions.promotionsProducts
            }
        },
        methods: {
            ...mapActions([
                'catalogPromotionsCreate',
                'catalogPromotionsProducts',
            ]),
            async getProducts() {
                await this.catalogPromotionsProducts()
            },
            async create() {
                const formData = new FormData();
                formData.append("photo", this.$refs.photo.files[0]);
                formData.append("title", this.title);
                formData.append("discount", this.discount);
                formData.append("is_visible", this.is_visible);
                formData.append("is_weekly", this.is_weekly);
                formData.append("is_carousel", this.is_carousel);
                formData.append("time_from", this.$moment(this.time_from).unix());
                formData.append("time_to", this.$moment(this.time_to).unix());
                formData.append("product_ids", this.product_ids);

                await this.catalogPromotionsCreate(formData)
            }
        },
        created() {
            this.title = this.$route.query.title;
            this.discount = this.$route.query.discount;
            this.is_visible = this.$route.query.is_visible;
            this.time_from = this.$route.query.time_from;
            this.time_to = this.$route.query.time_to;
            this.is_weekly = this.$route.query.is_weekly !== undefined && this.$route.query.is_weekly === 1 ? 1 : 0;
            this.getProducts()
        },
    }
</script>